if (document.getElementById('cart')) {
    const cart = new Vue({
            el: '#cart',
            name: "cart",
            data: {
                products: []
            },
            created: function () {
                this.loadCartProducts();
            },
            methods: {
                async loadCartProducts() {
                    axios.post('/ajax/cart/getProducts').then(response => {
                        this.products = response.data;
                    }).catch(function (error) {
                        location.reload();
                    });

                }
            }


        })
    ;
}

if (document.getElementById('search')) {
    const cart = new Vue({
        el: '#search',
        name: "search",
        data: {
            products: []
        },
    });
}

if (document.getElementById('product_details')) {
    const p_details = new Vue({
        el: '#product_details',
        props: ['img', 'id', 'product_name', 'price_adult', 'price_young', 'price_child', 'attributes', 'short_url', 'min_date', 'lat_lng', 'adult_age', 'young_age', 'child_age'],
        data: {
            width: window.innerWidth
        },
        created: function () {
           let listener= window.addEventListener('resize', this.handleResize);
           console.log(listener);
            this.loadAttributes();
        },
        computed: {
            product: function () {
                return {
                    img0: this.img,
                    name: this.product_name,
                    short_url: this.short_url,
                    lat_lng: JSON.stringify(this.lat_lng),
                    adult_age: this.adult_age,
                    young_age: this.young_age,
                    child_age: this.child_age,
                }
            },
        },
        methods: {
            handleResize() {
                this.width = window.innerWidth
            },
            loadAttributes() {
                let product_url = window.location.pathname.split("/").pop();
                axios.post('/ajax/getProductAttributes', {
                    short_url: product_url,
                }).then(response => {
                    if (typeof response.data.attributes === "undefined")
                        this.attributes = [];
                    else this.attributes = response.data.attributes;

                    this.img = response.data.product.img;
                    this.min_date = response.data.product.min_date;
                    this.id = response.data.product.id;
                    this.short_url = response.data.product.short_url;
                    this.product_name = response.data.product.name;
                    this.price_adult = response.data.product.base_price_adult;
                    this.price_child = response.data.product.base_price_child;
                    this.price_young = response.data.product.base_price_young;
                    this.lat_lng = JSON.parse(response.data.product.lat_lng);
                    this.adult_age = JSON.parse(response.data.product.adult_age);
                    this.child_age = JSON.parse(response.data.product.child_age);
                    this.young_age = JSON.parse(response.data.product.young_age);
                }).catch(function (error) {
                    console.log(error)
                });
            }
        },
        mounted: function () {

        },
    });
}

if (document.getElementById('home')) {
    const home = new Vue({
        el: '#home',
        name: 'Home'
    });
}

const header = new Vue({
    el: '#header',
    name: "header",
    data: {
        cart_products: [],
        SearchMounted: false,
    },
    computed: {
        width(){
            return this.$eventBus.width;
        },
        n_items: {
            get: function () {
                return this.cart_products.length
            },
            set: function (val) {
            }
        },
    },
    methods: {

    },
    mounted(){

    },
    created: function () {
        axios.post('/ajax/cart/getProducts').then(response => {
            this.cart_products = response.data;
            this.n_items = response.data.length;
        }).catch(function (error) {
            location.reload();
        });
    },
});

if (document.getElementById('product')) {
    const product = new Vue({
        el: '#product',
        name: 'Product'
    });
}

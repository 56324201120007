
require('./vendor');
window.Vue = require('vue');

require('./autoloader');
Vue.prototype.$eventBus = new Vue({
    data:{
        width:window.innerWidth
    },
    created(){
        window.addEventListener('resize', this.handleResize);

    },
    methods:{
        handleResize(){
            this.width=window.innerWidth;
        }
    }
});
require('./vue_apps');
require('./active');


